import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager", webpackExports: ["SGridPadding"] */ "/vercel/path0/src/base-components/grid-padding/styles/SGridPadding.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ButtonInner"] */ "/vercel/path0/src/components/button/ButtonInner.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SCompSmallActionBox"] */ "/vercel/path0/src/components/comp-small-action-box/styles/SCompSmallActionBox.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SCompSmallActionBoxButtonsWrapper"] */ "/vercel/path0/src/components/comp-small-action-box/styles/SCompSmallActionBoxButtonsWrapper.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SCompSmallActionBoxRichContentWrapper"] */ "/vercel/path0/src/components/comp-small-action-box/styles/SCompSmallActionBoxRichContentWrapper.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SCompSmallActionBoxTitle"] */ "/vercel/path0/src/components/comp-small-action-box/styles/SCompSmallActionBoxTitle.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SCompSmallActionBoxWrapper"] */ "/vercel/path0/src/components/comp-small-action-box/styles/SCompSmallActionBoxWrapper.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SFooter"] */ "/vercel/path0/src/components/footer/styles/SFooter.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SFooterLegalAndSocial"] */ "/vercel/path0/src/components/footer/styles/SFooterLegalAndSocial.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SFooterLegalAndSocialLeft"] */ "/vercel/path0/src/components/footer/styles/SFooterLegalAndSocialLeft.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SFooterLegalAndSocialLeftCopyright"] */ "/vercel/path0/src/components/footer/styles/SFooterLegalAndSocialLeftCopyright.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SFooterLegalAndSocialLeftLegal"] */ "/vercel/path0/src/components/footer/styles/SFooterLegalAndSocialLeftLegal.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SFooterLegalAndSocialRight"] */ "/vercel/path0/src/components/footer/styles/SFooterLegalAndSocialRight.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SFooterLegalAndSocialRightIcon"] */ "/vercel/path0/src/components/footer/styles/SFooterLegalAndSocialRightIcon.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SFooterLogo"] */ "/vercel/path0/src/components/footer/styles/SFooterLogo.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SFooterSection"] */ "/vercel/path0/src/components/footer/styles/SFooterSection.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SFooterSectionLinks"] */ "/vercel/path0/src/components/footer/styles/SFooterSectionLinks.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SFooterSections"] */ "/vercel/path0/src/components/footer/styles/SFooterSections.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SFooterSectionTitle"] */ "/vercel/path0/src/components/footer/styles/SFooterSectionTitle.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SFooterSubscribe"] */ "/vercel/path0/src/components/footer/styles/SFooterSubscribe.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SFooterWrapper"] */ "/vercel/path0/src/components/footer/styles/SFooterWrapper.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["PageHeader"] */ "/vercel/path0/src/components/header/page-header/PageHeader.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["NextImage"] */ "/vercel/path0/src/components/next-image/NextImage.tsx");
